html {
  margin: 0 !important;
  text-rendering: optimizelegibility;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  touch-action: manipulation;
  /*min-height: calc(100vh - 18%);*/

  /*border: solid hotpink 3px;*/
  /*display: flex;*/
  /*flex: 1;*/
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

body {
  margin: 0px !important;
  /*display: flex;*/
  /*flex: 1;*/
  min-height: 100vh;
  min-height: -webkit-fill-available;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-x pan-y;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@font-face {
  font-family: 'UKNumberPlate';
  font-style: normal;
  font-weight: normal;
  src: local('UKNumberPlate'), url('./static/fonts/UKNumberPlate.woff') format('woff');
}
